<template>
  <!-- prettier-ignore -->
  <div
    class="chip"
    :class="classes"
    >
    <div
      class="chip__trigger"
      tabindex="0"
      @click="toggle"
      @keydown.enter.prevent="toggle"
      @keydown.up.prevent="toggle"
      @keydown.down.prevent="toggle"
      >
      <slot name="leading">
        <the-icon
          v-if="props.hasSelectedIcon && selectedLabel"
          art="solid"
          name="check"
        />
      </slot>
      <span
        class="chip__text body-2"
        v-html="displayText"
      />
      <the-icon
        art="solid"
        :name="dropdownRef?.isOpen ? 'chevron-up' : 'chevron-down'"
      />
    </div>

    <basic-dropdown-menu
      :id="dropdownId"
      ref="dropdownRef"
      class="chip__dropdown"
      :name="$props.name"
      :options="$props.options"
      :selected="model"
      @select="selectItem"
    />
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import BasicDropdownMenu from '@/components/Basic/DropdownMenu'

// INIT
const model = defineModel({ type: [String, Object, Number] })
const props = defineProps({
  hasSelectedIcon: {
    type: Boolean,
    default: false,
  },

  name: {
    type: String,
    required: true,
  },

  options: {
    type: Array,
    required: true,
  },

  placeholder: {
    type: String,
    required: true,
  },
})

// DATA
const KEY = uuidv4()
const dropdownRef = ref(null)
const selectedLabel = ref(null)

const dropdownId = `dropdown-${KEY}`

// COMPUTED
const classes = computed(() => {
  return {
    'chip--selected': !!selectedLabel.value,
  }
})
const displayText = computed(() => {
  return selectedLabel.value || props.placeholder
})

// METHODS
function selectItem(item) {
  selectedLabel.value = item.label
  model.value = item.value
}

function toggle() {
  dropdownRef.value.toggle()
}

// WATCHERS
watch(
  model,
  value => {
    if (value !== undefined) {
      const item = props.options.find(x => x.value === model.value)
      selectedLabel.value = item.label
    }
  },
  { immediate: true }
)
</script>

<style global>
:root,
.appearance-positive {
  --dvp-chip-color-text: var(--on-surface);
  --dvp-chip-color-background: var(--Interaction-States-Chips-Positives-unselected-enabled-positive);
  --dvp-chip-color-border: var(--on-surface);
  --dvp-chip-color-text-hovered: var(--on-surface);
  --dvp-chip-color-background-hovered: var(--Interaction-States-Chips-Positives-unselected-hovered-positive);
  --dvp-chip-color-border-hovered: var(--surface-dim);
  --dvp-chip-color-text-active: var(--on-surface);
  --dvp-chip-color-background-active: var(--Interaction-States-Chips-Positives-unselected-pressed-positive);
  --dvp-chip-color-border-active: var(--surface-dim);
  --dvp-chip-color-text-disabled: var(--Interaction-States-Chips-Positives-on-unselected-disabled-positive);
  --dvp-chip-color-background-disabled: var(--Interaction-States-Chips-Positives-unselected-disabled-positive);
  --dvp-chip-color-border-disabled: var(--Interaction-States-Chips-Positives-on-unselected-disabled-positive);

  --dvp-chip-selected-color-text: var(--Interaction-States-Chips-Positives-on-selected-enabled-positive);
  --dvp-chip-selected-color-background: var(--Interaction-States-Chips-Positives-selected-enabled-positive);
  --dvp-chip-selected-color-border: var(--Interaction-States-Chips-Positives-selected-enabled-positive);
  --dvp-chip-selected-color-text-hovered: var(--Interaction-States-Chips-Positives-on-selected-hovered-positive);
  --dvp-chip-selected-color-background-hovered: var(--Interaction-States-Chips-Positives-selected-hovered-positive);
  --dvp-chip-selected-color-border-hovered: var(--Interaction-States-Chips-Positives-selected-hovered-positive);
  --dvp-chip-selected-color-text-active: var(--Interaction-States-pressed-default-a);
  --dvp-chip-selected-color-background-active: var(--Interaction-States-Chips-Positives-selected-pressed-positive);
  --dvp-chip-selected-color-border-active: var(--Interaction-States-Chips-Positives-selected-pressed-positive);
  --dvp-chip-selected-color-text-disabled: var(--Interaction-States-Chips-Positives-on-selected-disabled-positive);
  --dvp-chip-selected-color-background-disabled: var(--Interaction-States-Chips-Positives-selected-disabled-positive);
  --dvp-chip-selected-color-border-disabled: var(--Interaction-States-Chips-Positives-selected-disabled-positive);

  --dvp-chip-color-offset: var(--on-surface);
}

.appearance-inverted {
  --dvp-chip-color-text: var(--Interaction-States-Chips-Negatives-on-unselected-enabled-negative);
  --dvp-chip-color-background: transparent;
  --dvp-chip-color-border: var(--Interaction-States-Chips-Negatives-on-unselected-enabled-negative);
  --dvp-chip-color-text-hovered: var(--Interaction-States-Chips-Negatives-on-unselected-hovered-negative);
  --dvp-chip-color-background-hovered: transparent;
  --dvp-chip-color-border-hovered: var(--Interaction-States-Chips-Negatives-unselected-hovered-negative);
  --dvp-chip-color-text-active: var(--Interaction-States-Chips-Negatives-on-unselected-pressed-negative);
  --dvp-chip-color-background-active: transparent;
  --dvp-chip-color-border-active: var(--Interaction-States-Chips-Negatives-unselected-pressed-negative);
  --dvp-chip-color-text-disabled: var(--Interaction-States-disabled-inverse);
  --dvp-chip-color-background-disabled: transparent;
  --dvp-chip-color-border-disabled: var(--Interaction-States-disabled-inverse);

  --dvp-chip-selected-color-text: var(--Interaction-States-Chips-Negatives-on-selected-enabled-negative);
  --dvp-chip-selected-color-background: var(--Interaction-States-Chips-Negatives-selected-enabled-negative);
  --dvp-chip-selected-color-border: var(--Interaction-States-Chips-Negatives-selected-enabled-negative);
  --dvp-chip-selected-color-text-hovered: var(--Interaction-States-Chips-Negatives-on-selected-hovered-negative);
  --dvp-chip-selected-color-background-hovered: var(--Interaction-States-Chips-Negatives-selected-hovered-negative);
  --dvp-chip-selected-color-border-hovered: var(--Interaction-States-Chips-Negatives-selected-hovered-negative);
  --dvp-chip-selected-color-text-active: var(--Interaction-States-Chips-Negatives-on-selected-pressed-negative);
  --dvp-chip-selected-color-background-active: var(--Interaction-States-Chips-Negatives-selected-pressed-negative);
  --dvp-chip-selected-color-border-active: var(--Interaction-States-Chips-Negatives-selected-pressed-negative);
  --dvp-chip-selected-color-text-disabled: var(--Interaction-States-Chips-Positives-on-selected-disabled-positive);
  --dvp-chip-selected-color-background-disabled: var(--Interaction-States-Chips-Positives-selected-disabled-positive);
  --dvp-chip-selected-color-border-disabled: var(--Interaction-States-Chips-Positives-selected-disabled-positive);

  --dvp-chip-color-offset: var(--on-inverse-surface-variant);
}

.chip__dropdown .item__label {
  padding: var(--fixed-spacing-fix-01) var(--fixed-spacing-fix-02);
}
</style>

<style scoped>
.chip {
  display: inline-block;
  position: relative;

  &__trigger {
    border-width: 1px;
    border-style: solid;
    border-radius: var(--Interaction-States-Chips-Borders-chips-border-radius);
    padding: var(--fixed-spacing-fix-01) var(--fixed-spacing-fix-02);
    display: flex;
    gap: var(--fixed-spacing-fix-02);
    align-items: center;
    cursor: pointer;

    color: var(--dvp-chip-color-text);
    background-color: var(--dvp-chip-color-background);
    border-color: var(--dvp-chip-color-border);

    &:hover {
      text-decoration: underline;
      color: var(--dvp-chip-color-text-hovered);
      background-color: var(--dvp-chip-color-background-hovered);
      border-color: var(--dvp-chip-color-border-hovered);
    }

    &:active {
      text-decoration: underline;
      color: var(--dvp-chip-color-text-active);
      background-color: var(--dvp-chip-color-background-active);
      border-color: var(--dvp-chip-color-border-active);
    }

    &[disabled] {
      pointer-events: none;
      color: var(--dvp-chip-color-text-disabled);
      background-color: var(--dvp-chip-color-background-disabled);
      border-color: var(--dvp-chip-color-border-disabled);
    }

    &:focus-visible {
      outline: 2px solid var(--dvp-chip-color-offset);
      outline-offset: var(--fixed-spacing-fix-01);
      border-radius: var(--fixed-border-radius-fix-03);
    }
  }

  &--selected {
    .chip__trigger {
      font-size: 18px;

      color: var(--dvp-chip-selected-color-text);
      background-color: var(--dvp-chip-selected-color-background);
      border-color: var(--dvp-chip-selected-color-border);

      &:hover {
        color: var(--dvp-chip-selected-color-text-hovered);
        background-color: var(--dvp-chip-selected-color-background-hovered);
        border-color: var(--dvp-chip-selected-color-border-hovered);
      }

      &:active {
        color: var(--dvp-chip-selected-color-text-active);
        background-color: var(--dvp-chip-selected-color-background-active);
        border-color: var(--dvp-chip-selected-color-border-active);
      }

      &[disabled] {
        color: var(--dvp-chip-selected-color-text-disabled);
        background-color: var(--dvp-chip-selected-color-background-disabled);
        border-color: var(--dvp-chip-selected-color-border-disabled);
      }
    }
  }
}

.chip__text {
  min-width: 80px;
}
</style>

<template>
  <!-- prettier-ignore -->
  <basic-filter-chip
    :model-value="selectedCriteria"
    :name="$props.name"
    :placeholder="$props.placeholder"
    :options="items"
    @update:modelValue="findOption"
  />
</template>

<script setup>
import { computed, ref, watch } from 'vue'

import { events$ } from '@/services'

import useCriteria from '@/hooks/useCriteria'

import BasicFilterChip from '@/components/Basic/FilterChip'

import { EVENT_BASKET, EVENT_PRODUCT } from '@/config/events'

// HOOKS
const { getFranchiseValuesByKey } = useCriteria()

// INIT
const emit = defineEmits(['select'])
const props = defineProps({
  criteriaKeyPair: {
    type: String,
    default: 'value',
  },

  name: {
    type: String,
    required: true,
  },

  options: {
    type: Array,
    required: true,
  },

  placeholder: {
    type: String,
    default: '',
  },

  source: {
    type: String,
    required: true,
  },
})

// DATA
const selectedCriteria = ref(null)

// COMPUTED
const items = computed(() => {
  return getFranchiseValuesByKey(props.options, key.value)
})

const key = computed(() => {
  if (keys.value.length > 1) {
    return keys.value[0]
  } else {
    return props.criteriaKeyPair
  }
})

const keys = computed(() => {
  return props.criteriaKeyPair.split(',')
})

// METHODS
function findOption(selected) {
  const selectedOption = props.options.find(o => o.selected)
  const selectedDuration = selectedOption.duration === 'F' ? '1' : selectedOption.duration
  let match
  if (keys.value.length === 1) {
    match = props.options.find(o => o[key.value] === selected)
  } else {
    match = props.options.find(o => {
      return (
        o.duration === selectedDuration &&
        ((key.value === 'amValue' && o['gfValue'] === selectedOption['gfValue'] && o[key.value] === selected) ||
          (key.value === 'gfValue' && o['amValue'] === selectedOption['amValue'] && o[key.value] === selected))
      )
    })
  }

  emit('select', Object.assign({}, match, { selected: true }))

  events$.emit(EVENT_BASKET.TRACK_AFTER_UPDATE, {
    ruleId: props.name === 'duration' ? EVENT_PRODUCT.DURATION_CHANGED : EVENT_PRODUCT.FRANCHISE_CHANGED,
    payload: props.source,
  })
}

watch(
  () => props.options,
  val => {
    const selectedOption = val.find(o => o.selected)
    selectedCriteria.value = selectedOption ? selectedOption[key.value] : undefined
  },
  { deep: true, immediate: true }
)
</script>

<style scoped>
.cselector {
  .selected {
    color: purple;
  }
}
</style>
"

<template>
  <div
    v-if="hasProductOptions"
    class="options"
  >
    <div
      v-if="hasProductAccident"
      class="option"
    >
      <div class="title-2">
        <span v-text="optionTitles.accident.title" />
        <the-icon
          v-tooltip="optionTitles.accident.tooltip"
          art="regular"
          class="option__icon"
          html-tooltip
          name="circle-info"
          prevent-input-toggle
        />
      </div>
      <product-accident
        :option="$props.product.prices.find(p => p.selected)"
        :source="$props.source"
        @select="selectedOption => $emit('change', selectedOption)"
      />
    </div>

    <div
      v-if="hasProductFranchise"
      class="option"
    >
      <div class="title-2">
        <span v-text="optionTitles.franchise.title" />
        <the-icon
          v-tooltip="optionTitles.franchise.tooltip"
          art="regular"
          class="option__icon"
          html-tooltip
          name="circle-info"
          prevent-input-toggle
        />
      </div>
      <product-criteria-selector
        name="franchise"
        :options="$props.product.prices"
        :source="$props.source"
        @select="selectedOption => $emit('change', selectedOption)"
      />
    </div>

    <div
      v-if="hasProductVitalFranchise"
      class="options"
    >
      <div class="option">
        <div class="title-2">
          <span v-text="optionTitles.amValue.title" />
          <the-icon
            v-if="optionTitles.amValue.tooltip"
            v-tooltip="optionTitles.amValue.tooltip"
            art="regular"
            html-tooltip
            name="circle-info"
            prevent-input-toggle
          />
        </div>
        <product-criteria-selector
          criteria-key-pair="amValue, gfValue"
          name="amValue"
          :options="$props.product.prices"
          :source="$props.source"
          @select="selectedOption => $emit('change', selectedOption)"
        />
      </div>
      <div class="option">
        <div class="title-2">
          <span v-text="optionTitles.gfValue.title" />
          <the-icon
            v-if="optionTitles.gfValue.tooltip"
            v-tooltip="optionTitles.gfValue.tooltip"
            art="regular"
            html-tooltip
            name="circle-info"
            prevent-input-toggle
          />
        </div>
        <product-criteria-selector
          criteria-key-pair="gfValue, amValue"
          name="gfValue"
          :options="$props.product.prices"
          :source="$props.source"
          @select="selectedOption => $emit('change', selectedOption)"
        />
      </div>
    </div>

    <div
      v-if="hasProductDuration"
      class="option"
    >
      <div class="title-2">
        <span v-text="optionTitles.duration.title" />
      </div>
      <product-criteria-selector
        criteria-key-pair="duration"
        name="duration"
        :options="$props.product.prices"
        :source="$props.source"
        @select="selectedOption => $emit('change', selectedOption)"
      />
    </div>

    <div
      v-if="$props.product.medicalOffice"
      class="option"
    >
      <div class="option__doctor">
        <span
          class="title-2"
          v-text="$t('product.doctor.titleShort')"
        />
        <span
          v-if="$props.product.doctor"
          class="body-2"
          v-text="doctor"
        />

        <template v-if="discountGroup">
          <div v-show="false">
            <div :id="`discountGroup_${$props.product.productId}`">
              <h4 v-text="$t('medicalofficesearch.reductions.title')" />
              <div v-text="$t('medicalofficesearch.reductions.text')" />
              <div>
                <div>
                  <the-icon
                    class="doctor__icon"
                    art="solid"
                    name="signal-good"
                  />
                  {{ $t('medicalofficesearch.reductions.level.highest') }}
                </div>
                <div>
                  <the-icon
                    class="doctor__icon"
                    art="solid"
                    name="signal-fair"
                  />
                  {{ $t('medicalofficesearch.reductions.level.medium') }}
                </div>
                <div>
                  <the-icon
                    class="doctor__icon"
                    art="solid"
                    name="signal-weak"
                  />
                  {{ $t('medicalofficesearch.reductions.level.lowest') }}
                </div>
              </div>
              <br />
              <div v-html="$t('medicalofficesearch.reductions.note')" />
              <br />
            </div>
          </div>

          <div
            v-if="!isEmpty(discountTooltip)"
            class="doctor-discount__title"
          >
            <span
              v-tooltip="discountTooltip"
              class="body-2"
            >
              {{ $t('product.reduction') }}
              <the-icon
                class="doctor__icon"
                art="solid"
                :name="discountIcon"
              />
            </span>
          </div>
        </template>
      </div>
      <basic-text-button
        :label="$props.product.doctor ? $t('form.edit') : $t('product.actions.add')"
        @click="openMedicalOfficeSearch"
      >
        <template #trailing>
          <the-icon
            art="solid"
            name="pencil"
          />
        </template>
      </basic-text-button>
    </div>

    <basic-message
      v-if="showFranchiseInfoMessage"
      :severity="NOTIFICATION.INFO"
      :content="$t('product.detail.franchiseInfo.text')"
      :title-label="$t('product.detail.franchiseInfo.title')"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import isEmpty from 'lodash/isEmpty'

import { events$ } from '@/services'

import useProduct from '@/hooks/useProduct'

import ProductAccident from '@/components/Product/ProductAccident'
import ProductCriteriaSelector from '@/components/Product/CriteriaSelector'

import { BasicMessage, BasicTextButton } from '@/components/Basic'

import { CATEGORY_TYPE, NOTIFICATION } from '@/config/constants'
import { EVENT_MEDICAL_OFFICE_MODAL_OPEN } from '@/config/events'

// HOOKS
const { getAVMId, getOptionTitles, hasAccidentToggle, hasDuration, hasFranchise, hasOptions, hasVitalFranchise } =
  useProduct()

// INIT
const emit = defineEmits(['change'])
const props = defineProps({
  categoryId: {
    type: String,
    required: true,
  },

  groupId: {
    type: String,
    required: true,
  },

  person: {
    type: Object,
    required: true,
  },

  product: {
    type: Object,
    required: true,
  },

  showDoctorReduction: {
    type: Boolean,
    default: false,
  },

  source: {
    type: String,
    required: true,
  },
})

// DATA
const discountTooltip = ref({})

// COMPUTED
const discountGroup = computed(() => {
  if (!props.product.showDiscountGroup || !doctor.value) return false
  return network.value?.discountGroup
})

const discountIcon = computed(() => {
  const group = parseInt(discountGroup.value)
  switch (group) {
    case 1:
    default:
      return 'signal-good'

    case 2:
      return 'signal-fair'

    case 3:
      return 'signal-weak'
  }
})

const doctor = computed(() => {
  if (!props.product.doctor) return false

  const { firstName, lastName, address } = props.product.doctor
  return [firstName, lastName, `${address.zipCode} ${address.town}`].filter(n => n).join(' ')
})

const hasProductAccident = computed(() => {
  return (
    hasAccidentToggle({
      categoryId: props.categoryId,
      dateOfBirth: props.person.personData.dateOfBirth,
      kvgContractStartDate: props.person.kvgContractStartDate,
    }) && optionTitles.value.accident?.title
  )
})

const hasProductDuration = computed(() => {
  return hasDuration(props.product) && optionTitles.value.duration
})

const hasProductFranchise = computed(() => {
  return hasFranchise(props.product) && optionTitles.value.franchise?.title
})

const hasProductOptions = computed(() => {
  return props.product.prices && hasOptions(props.product)
})

const hasProductVitalFranchise = computed(() => {
  return hasVitalFranchise(props.product)
})

const network = computed(() => {
  if (!props.product.doctor) return false

  return props.product.doctor.medicalOfficeNets.find(n => n.avmDefinition === networkId.value)
})

const networkId = computed(() => {
  return getAVMId(props.product.productId)
})

const optionTitles = computed(() => {
  return getOptionTitles(props.product.productId, props.groupId, props.categoryId)
})

const showFranchiseInfoMessage = computed(() => {
  const selectedOption = props.product.prices.find(p => p.selected === true)

  if (!selectedOption) return false

  return props.categoryId === CATEGORY_TYPE.HOSP && selectedOption.value && selectedOption.value !== 0
})

// METHODS
function getDiscountContent() {
  const __el = document.getElementById(`discountGroup_${props.product.productId}`)

  if (__el) {
    Object.assign(discountTooltip.value, {
      allowHTML: true,
      content: __el,
    })
  } else {
    window.setTimeout(() => {
      getDiscountContent()
    }, 1000)
  }
}

function openMedicalOfficeSearch() {
  events$.emit(EVENT_MEDICAL_OFFICE_MODAL_OPEN, {
    source: props.source,
    personId: props.personId,
    product: props.product,

    callback: data => {
      emit('change', data)
    },
  })
}

// LIFECYCLE HOOKS
onMounted(() => {
  if (props.showDoctorReduction) {
    watch(
      () => discountGroup,
      value => {
        if (value) {
          getDiscountContent()
        }
      },
      { immediate: true }
    )
  }
})
</script>

<style scoped>
.options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: var(--fixed-spacing-fix-06);
  row-gap: var(--fixed-spacing-fix-04);
  align-items: flex-start;
  min-height: 85px; /* Magic number as there is a scroll-height issue with the toggle */
}

.option {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: var(--fixed-spacing-fix-04);
  row-gap: var(--fixed-spacing-fix-02);
  align-items: center;
  min-height: 34px; /* Magic number, the chip/toggle have different heights and mess with the centering of text */
}

.option__icon {
  margin: 0 0 0 var(--fixed-spacing-fix-02);
}

.option__doctor {
  display: flex;
  flex-direction: row;
  column-gap: var(--fixed-spacing-fix-02);
}
</style>

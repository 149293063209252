<template>
  <!-- prettier-ignore -->
  <basic-toggle
    v-model="toggleValue"
    name="accident"
    @update:modelValue="onChange"
  >
    <slot />
  </basic-toggle>
</template>

<script setup>
import { ref, watch } from 'vue'

import { events$ } from '@/services'

import BasicToggle from '@/components/Basic/Toggle'

import { EVENT_BASKET, EVENT_PRODUCT } from '@/config/events'

// INIT
const emit = defineEmits(['select'])
const props = defineProps({
  option: {
    type: Object,
    required: true,
  },

  source: {
    type: String,
    required: true,
  },
})

// DATA
const toggleValue = ref(props.option.toggle)

// METHODS
function onChange(toggle) {
  const option = Object.assign({}, props.option, { toggle: toggle })
  emit('select', option)
  events$.emit(EVENT_BASKET.TRACK_AFTER_UPDATE, {
    ruleId: EVENT_PRODUCT.ACCIDENT_CHANGED,
    payload: props.source,
  })
}

// WATCHERS
watch(
  () => props.option,
  __value => {
    toggleValue.value = __value.toggle
  }
)
</script>
